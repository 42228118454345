import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookFrom from "../../components/bookForm"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
//import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import NextServerActionsBook from "../../../static/images/commonImg/nextjs-server-actions.svg"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
import TSmasterBook from "../../../static/images/commonImg/typescript-master-book.svg"
import NextFullAppFolder1 from "../../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import RegisterNewsLetterBody from "../../components/registerNewsLetter"

const generalData = {
    title: "はじめてつくるバックエンドサーバー発展編（Node.js & Express & MongoDB）",
    updated: "2024/9/8",
    urlToApp: "https://nodebook-advanced-blog.onrender.com",
    urlToCode1: "https://github.com/mod728/node-express-book----advanced",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B09X56PSCQ",
    reviewLink2: ""

}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*New Release* 2024年11月発売", title: "Next.js Server Actionsでつくるフルスタックアプリ", img: NextServerActionsBook, url: "https://www.amazon.co.jp/dp/B0DMQLP8Z9", description: "Next.jsビギナー向けの入門書。<br/><br/>Next.jsとServer Actionsを使って、ログイン機能をもった本格的なアプリケーション開発の方法を紹介。<br/><br/>2024年10月にリリースされたばかりの最新のNext.jsバージョン15使用。"},
    {oldPrice: "", newPrice: "", newRelease: "*2024年2月発売", title: "React全部入り：Reactマスター", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactを『なんとなく』は使える人が、2日で自信を持ってReactを書ける本！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、そして応用までの全範囲をカバーした待望の解説書。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになる一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "*2024年3月発売", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterBook, url: "https://monotein.base.shop/p/00002", description: "2日でTypeScript + Reactの苦手意識を捨てされる本。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用書。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "Next.jsでつくるフルスタックサイト　前編・後編", img: NextFullAppFolder1, url: "https://www.amazon.co.jp/dp/B0CNXPW7K2", description: "最新バージョンのNext.jsとAppフォルダを使って、バックエンドとフロントエンドの開発手法を紹介。<br/><br/>アプリ開発の基礎の基礎から、オンラインでの公開方法までの流れをすべて紹介している充実の内容。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "Node.js", url: "https://nodejs.org/en"},
            {name: "npmパッケージのバージョン情報", url: "https://github.com/mod728/node-express-book----advanced/blob/master/package.json"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},    
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},        
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "JSON Formatter", url: "https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa"},
            {name: "ウェブアプリケーションの例", url: "https://react-book-corona-tracker-app.netlify.app/world"},
            {name: "APIの例", url: "https://monotein-books.vercel.app/api/corona-tracker/summary"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "MongoDB", url: "https://www.mongodb.com/"},
            {name: "Thunder Client", url: "https://marketplace.visualstudio.com/items?itemName=rangav.vscode-thunder-client"},
            {name: "1つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/1st-blog.md"},
            {name: "2つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/2nd-blog.md"},
            {name: "3つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/3rd-blog.md"},
            {name: "4つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/4th-blog.md"},
            {name: "5つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/5th-blog.md"},
            {name: "6つ目の記事データ", url: "https://github.com/mod728/node-express-book----advanced/blob/master/blog-data/6th-blog.md"},
            {name: "画像データ", url: "https://github.com/mod728/node-express-book----advanced/tree/chapter3/public/images"},
            {name: "第2章完了時のソースコード", url: "https://github.com/mod728/node-express-book----advanced/tree/chapter2"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "index.js", url: "https://github.com/mod728/node-express-book----advanced/blob/chapter3/index.js"},
            {name: "styles.css", url: "https://github.com/mod728/node-express-book----advanced/blob/chapter3/public/css/styles.css"},
            {name: "第3章完了時のソースコード", url: "https://github.com/mod728/node-express-book----advanced/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "Git", url: "https://git-scm.com/downloads"},
            {name: "Render", url: "https://render.com"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "第5章完了時のソースコード", url: "https://github.com/mod728/node-express-book----advanced"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
]

const NodeBook2Links = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                        <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                        <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.shop") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="NodeBook2"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default NodeBook2Links
